import { Link } from 'gatsby';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { assoc, empty, getIn, merge, reduceKv, removeKv, selectKeys, someKv, threadLast } from '../../utils/functional';
import { redirect, clearServiceWorkerCache, clearPluginOfflineCache } from '../../utils/redirect';
import { fieldState, fieldsState,
         formState, inputDisabledState,
         messageState, messagesState,
         remoteDataState, remoteDatomState,
         subscriptionTypeState } from '../../utils/state';

import * as buttonStyles from '../button.module.css';
import { colors } from '../colors';
import * as linkStyles from '../link.module.css';

import { CountryField } from './country';
import { labelNameTag, Input } from './Input';
import { log } from './log';
import { messageByFieldError } from '../message';
import { PromoCodeInput } from './PromoCode';
import { PaymentCardInput } from './PaymentCardInput';
import { sectionLabel } from './shared';
import * as styles from './signupform.module.css';
import { submit } from './submit';
import { loadUserData } from './validate';


//
// configuration

const fieldKs = ['country', 'email', 'nameFirst', 'nameLast', 'password', 'promoCode'];
const redirectDestination = '/welcome';


//
// child components

const EmailInput = () => {
    const k = 'email';
    const field = useRecoilValue(fieldState(k));
    const [existingData, setData] = useRecoilState(remoteDatomState(k));
    const onBlur = (v) => loadUserData(field)
          .then((r) => setData( assoc(existingData, field, r) ));
    return (
        <Input
          stateKey={ k }
          label='Email address'
          name='email-address'
          type='email'
          onBlur={ onBlur }
        />
    );
};

const CardDetails = () => {
    const paymentRequired = useRecoilValue(formState('paymentRequired'));
    
    if ( paymentRequired === false ) return ( <div/> );
    return (
        <div
          style={{ width: `100%`, maxWidth: `540px` }} >
          
          <div
            style={{
                width: `inherit`,
                margin: `40px 0 0`,
            }} >
            { labelNameTag( 'Card details' ) }
          </div>
          
          <div style={{ margin: `20px 0 0` }} >
            <PaymentCardInput />
          </div>
          
        </div>
    );
};


const Form = () => {

    const [inFlight, setInFlight]               = useRecoilState( inputDisabledState );
    const [paymentRequired, setPaymentRequired] = useRecoilState( formState('paymentRequired') );
    const fields                                = useRecoilValue( fieldsState(fieldKs) );
    const [fieldMessages, setFieldMessages]     = useRecoilState( messagesState(fieldKs) );
    const [globalMessage, setGlobalMessage]     = useRecoilState( messageState('global') );
    const updateGlobalMessage                   = (f) => setGlobalMessage( f(globalMessage) );
    const remoteData                            = useRecoilValue( remoteDataState(['email', 'promoCode']) );
    const subscriptionType                      = useRecoilValue( subscriptionTypeState );

    
    // state - initial values
    useEffect(() => {
        if (paymentRequired === null) setPaymentRequired(true);
    }, [paymentRequired, setPaymentRequired]);


    const stripe = useStripe();
    const elements = useElements();
    
    useEffect(() => {
        if (elements) {
            const cardElement = elements.getElement(CardElement);
            if (cardElement) {
                const disabled = inFlight || !stripe || false;
                cardElement.update({ disabled });
            }
        }
    }, [inFlight, elements, stripe]);
    
    
    const submitFn = (event) => {
        
        event.preventDefault();

        if ( inFlight || elements == null ) return false;

        updateGlobalMessage((m) => selectKeys(m, ['base', 'info']));
        setInFlight(true);

        
        submit({ fields, paymentRequired, remoteData, stripe, subscriptionType, elements })  // remoteData is used by validate
            .then((errorTypeByField) => {
                if ( errorTypeByField !== null ) {

                    log('submit',
                        { complimentary: ! paymentRequired,
                          error: errorTypeByField,
                          subscriptionType,
                          user: { email: fields.email }},
                        'warn');

                    const setGlobalErrorMsg = (s) => updateGlobalMessage((m) => merge(m, { error: s }));
                    
                    // single global error
                    if ( someKv((k,v) => k === 'global', errorTypeByField) ) {
                        const d = errorTypeByField.global;
                        var em = d;
                        if ( d.type ) em = getIn(messageByFieldError, ['global', d.type]);
                        if ( d.message ) em = d.message;
                        setGlobalErrorMsg(em);
                        return null;
                    }

                    // set field messages
                    const fmr = (a, fieldK, errorType) => assoc(a, fieldK,
                                                                getIn(messageByFieldError, [fieldK, errorType]) || errorType);
                    const countryInvalid = (k,v) => k === 'country' && v === 'invalid';
                    const m = threadLast( errorTypeByField, [removeKv, countryInvalid], [reduceKv, fmr, {}]);
                    const ofms = fieldKs.map((k) => assoc(fieldMessages[k], 'error', m[k]));
                    setFieldMessages(ofms);
                    
                    // global message: failed validations
                    if ( Object.keys(m).length > 0 ) {
                        const e = getIn( messageByFieldError, ['global', 'field']);
                        setGlobalErrorMsg(e);
                        return null;                        
                    }
                    // global message: country failed validation
                    if ( ! empty( someKv(countryInvalid, errorTypeByField)) ) {
                        const e = getIn( messageByFieldError, ['country', 'invalid']);
                        setGlobalErrorMsg(e);
                    }
                    return null;
                }

                // success
                clearServiceWorkerCache(); clearPluginOfflineCache();
                redirect(redirectDestination);
                return true;
            })
            .then( rv => { if (!rv) setInFlight(false); });
        return false;
    };


    return (
        <form
          onSubmit={ submitFn }
          style={{ width: `100%` }} >

          <div
            className={ styles.container }
            style={{
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `flex-start`,
                alignItems: `flex-start`
            }} >

            <div
              style={{
                  margin: `140px 0 0`,
                  width: `100%`,
                  fontSize: `14px`,
                  lineHeight: `1.79`,
                  letterSpacing: `0.14px`,
                  color: colors.black
              }} >

                Already a { subscriptionType === "SHIFTS_EMAIL_ONLY" ? "Subscriber" : "Member" }?

              <Link
                className={ linkStyles.signinLink }
                to={`/login`}
                style={{ margin: `0 0 0 10px` }} >
                Log In
              </Link>
              
            </div>

            <div
              className={ styles.fieldsContainer }
              style={{
                  margin: `40px 0 0`,
                  display: `flex`,
                  flexDirection: `column`,
                  justifyContent: `flex-start`,
                  alignItems: `flex-start`
              }} >
              
              { sectionLabel('Account Details') }

              <div style={{ margin: `30px 0 0`, width: `100%` }} >
                <Input
                  stateKey={ 'nameFirst' }
                  label='First name'
                  name='first-name'
                />

              </div>
              <div style={{ margin: `30px 0 0`, width: `100%` }} >
                <Input
                  stateKey={ 'nameLast' }
                  label='Last name'
                  name='last-name'
                />
              </div>
              <div style={{ margin: `30px 0 0`, width: `100%` }} >
                <EmailInput />
              </div>
              <div style={{ margin: `30px 0 0`, width: `100%` }} >
                <Input
                  stateKey={ 'password' }
                  label='Create password'
                  name='password'
                  type='password'
                />
              </div>
              
              <div style={{ margin: `80px 0 0`, width: `100%` }} >
                { sectionLabel('Payment Details') }
              </div>

              <div style={{ margin: `30px 0 0`, width: `100%` }} >
                <PromoCodeInput />
              </div>
              
              <CardDetails />
              
            </div>

            <CountryField />

            <div
              style={{
                  margin: `50px 0 0`,
                  width: `100%`,
                  minHeight: `25px`,
                  fontSize: `14px`,
                  color: colors.orange
              }} >
                { globalMessage ? globalMessage.info : "" }
            </div>

            <div
              style={{
                  margin: `20px 0 0`,
                  width: `100%`,
                  fontSize: `12px`,
                  lineHeight: `1.75`,
                  letterSpacing: `0.12px`,
                  color: colors.mediumDarkGrey
              }} >
                By clicking “Confirm { subscriptionType === "SHIFTS_EMAIL_ONLY" ? "Subscription" : "Membership" },” you agree to our 

              <Link
                  to={`/terms`}
                  style={{
                      margin: `0 0 0 2px`,
                      color: colors.mediumDarkGrey,
                      boxShadow: `none`,
                      textDecoration: `solid 1px underline ${colors.green}`
                  }} >
                  Terms of Use
              </Link>
                
                {
                    subscriptionType === "SHIFTS_EMAIL_ONLY" ? 
                        ". If a payment method was collected, you authorize 6Pages, Inc. to charge the amount indicated above now and a monthly Subscription fee each subsequent month on a recurring basis unless and until you cancel. You agree to receive the regular 6Pages email briefing and other periodic communications about our service."
                        :
                        ". If a payment method was collected, you authorize 6Pages, Inc. to charge the amount indicated above now and the full annual Membership fee each subsequent year on a recurring basis unless and until you cancel. You agree to receive the Member-only email briefing and other periodic communications about our service."
                }
              
            </div>

            <div
              name='MessageGlobal'
              style={{
                  margin: `15px 0 0`,
                  width: `100%`,
                  minHeight: `25px`,
                  fontSize: `13px`,
                  fontStyle: `italic`,
                  letterSpacing: `0.13px`,
                  color: colors.red
              }} >
                { globalMessage ? globalMessage.error : "" }
            </div>
            
            <div
              style={{
                  margin: `10px 0 95px 0`,
                  width: `100%`
              }} >

              <input
                type='submit'
                aria-label={ subscriptionType === "SHIFTS_EMAIL_ONLY" ? "Confirm Subscription" : "Confirm Membership" }
                value={ subscriptionType === "SHIFTS_EMAIL_ONLY" ? "Confirm Subscription" : "Confirm Membership" }
                disabled={ inFlight }
                className={ buttonStyles.submit }
                style={{
                    padding: `14px 26px`
                }} />
              
            </div>
          </div>
        </form>
    );
};

export default Form;
