import React from 'react';
import { useRecoilValue } from 'recoil';
import { centsToDollars, subscriptionPriceCents } from '../../utils/price';
import { subscriptionTypeState } from '../../utils/state';
import { subscriptionPeriod } from '../../utils/subscription';
import { colors } from '../colors';
import * as styles from './banner.module.css';



const TextContent = () => {

    const subscriptionType = useRecoilValue( subscriptionTypeState );
    
    if ( subscriptionType === "SHIFTS_EMAIL_ONLY" ) {
        return (
            <div
                style={{
                    margin: `5px 0 0`,
                    width: `100%`,
                    fontSize: `15px`,
                    lineHeight: `1.87`,
                    letterSpacing: `0.15px`,
                    color: colors.black
                }} >

                Become a Subscriber now to receive our <b>full 3 Shifts Editions</b> every week in your inbox. Our 3 Shifts Editions cover the top market shifts, designed to be read in 10 minutes.
                
            </div>
        );
    }

    return (
        <div
            style={{
                margin: `5px 0 0`,
                width: `100%`,
                fontSize: `15px`,
                lineHeight: `1.87`,
                letterSpacing: `0.15px`,
                color: colors.black
            }} >

            Become a Member now for <b>unlimited access to all 6Pages briefs</b> and the searchable repository. You'll also receive by email:
            
            <ul
                style={{ marginTop: `10px` }} >

                <li style={{
                        marginLeft: `3px`,
                        marginBottom: `11px`,
                        listStyleType: `disc`
                    }} >
                    <span style={{ fontWeight: `700`, margin: `0` }}>Member-only briefs</span>
                    , with a recent selection of our Quick Hit market signals
                </li>

                <li style={{
                        marginLeft: `3px`,
                        marginBottom: `11px`,
                        listStyleType: `disc`
                    }} >
                    <span style={{ fontWeight: `700`, margin: `0 3px 0 0` }}>3 Shifts Edition</span>
                    covering the top market shifts to know about every week, designed to be read in 10 minutes
                </li>
                
            </ul>
            
        </div>
    );
};



export const Banner = () => {

    const subscriptionType = useRecoilValue( subscriptionTypeState );
    
    return (
        <div
            className={ styles.container } >

            <div
                className={ styles.textContainer }
                style={{
                    display: `flex`,
                    flexDirection: `column`,
                    justifyContent: `flex-start`,
                    alignItems: `center`
                }} >

                <div className={ styles.topSpacer } />

                <div
                    style={{
                        flex: `0 0 250px`,
                        width: `100%`
                    }} >
                    
                    <div
                        style={{
                            margin: 0,
                            width: `100%`,
                            fontFamily: `"Overpass", sans-serif`,
                            fontSize: `30px`,
                            fontWeight: `bold`,
                            lineHeight: `1.13`,
                            color: colors.green
                        }} >
                        Make better and faster decisions with context.
                    </div>
                    
                    <div
                        style={{
                            margin: `25px 0 0`,
                            width: `100%`,
                            fontFamily: `"Overpass", sans-serif`,
                            fontSize: `24px`,
                            fontWeight: `bold`,
                            lineHeight: `1.25`,
                            color: colors.black
                        }} >
                        { '$' + String(centsToDollars(subscriptionPriceCents({ subscriptionType }))) + '/' + subscriptionPeriod[subscriptionType] }
                    </div>

                    { TextContent() }

                </div>

                <div className={ styles.bottomSpacer } />
                
            </div>

        </div>
    );
};
